export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_BY_SCOPE = "GET_AGENTS_BY_SCOPE";
export const SETTING_GET_AGENTS = "SETTING_GET_AGENTS";
export const GET_AGENTS_FILTERED = "GET_AGENTS_FILTERED";
export const CREATE_AGENT = "CREATE_AGENT";
export const DELETE_AGENT = "DELETE_AGENT";

export const GET_ROLE_AGENTS = "GET_ROLE_AGENTS";
export const GET_TAB_PERMISSONS_AGENTS = "GET_TAB_PERMISSONS_AGENTS";
export const GET_CHANNELS_AGENTS = "GET_CHANNELS_AGENTS";
export const GET_GROUPS_AGENTS = "GET_GROUPS_AGENTS";
export const GET_SESSION_TYPE_AGENTS = "GET_SESSION_TYPE_AGENTS";

export const UPDATE_STATUS_AGENTS = "UPDATE_STATUS_AGENTS";
export const UPDATE_ROLE_AGENTS = "UPDATE_ROLE_AGENTS";
export const UPDATE_PERMISSONS_AGENTS = "UPDATE_PERMISSONS_AGENTS";
export const UPDATE_TAB_PERMISSONS_AGENTS = "UPDATE_TAB_PERMISSONS_AGENTS";
export const UPDATE_GROUPS_AGENTS = "UPDATE_GROUPS_AGENTS";
export const UPDATE_GROUPS_SEETING_AGENTS = "UPDATE_GROUPS_SEETING_AGENTS";
export const UPDATE_PERMISSONS_SETTING_AGENTS =
  "UPDATE_PERMISSONS_SETTING_AGENTS";
export const UPDATE_SHIFTS_AGENTS = "UPDATE_SHIFTS_AGENTS";
export const UPDATE_AGENT_COMMUNITY_STATUS = "UPDATE_AGENT_COMMUNITY_STATUS";

export const RESEND_EMAIL_AGENT = "RESEND_EMAIL_AGENT";

export const CREATE_PERMISSONS_AGENTS = "CREATE_PERMISSONS_AGENTS";
export const NEW_SESSION_TYPES = "NEW_SESSION_TYPES";
export const ENABLE_ALL_CHANNELS = "ENABLE_ALL_CHANNELS";

export const LOCK_AGENT = 'LOCK_AGENT'
export const UNLOCK_AGENT = 'UNLOCK_AGENT'
export const GET_SESSIONS_ACTIVE_AGENT = 'GET_SESSIONS_ACTIVE_AGENT'
export const REVOKE_SESSION_ACTIVE_AGENT="REVOKE_SESSION_ACTIVE_AGENT"
export const CLEAR_REVOKE_SESSION_ACTIVE_AGENT="CLEAR_REVOKE_SESSION_ACTIVE_AGENT"
export const LOADING_SESSIONS_ACTIVE_AGENT="LOADING_SESSIONS_ACTIVE_AGENT"
export const LOADING_REVOKE_SESSIONS_AGENT="LOADING_REVOKE_SESSIONS_AGENT"