import axios from "axios";

export const controller = new AbortController();
export const signal = { signal: controller.signal };
const http = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  // withCredentials:true,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use((req: any) => {
  if (localStorage.getItem("token")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  return req;
});

http.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    const originalRequest = err.config;
    if (err.response.status === 401 || err.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("socketId");
      controller.abort();

      // Attempt to refresh the token
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/oauth/refresh`,
          {
            token: localStorage.getItem("refreshToken"),
          }
        );

        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.rememberMeToken);

          // Retry the original request with the new token
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        window.location.reload();
      }
    }
    return Promise.reject(err);
  }
);

export default http;

export const httpSecurity = axios.create({
  baseURL: process.env.REACT_APP_API_HOST2,
  // withCredentials:true,
  headers: {
    "Content-type": "application/json",
  },
});
