import http, { signal } from "../config/httpComman";
// import httpSecurity, { signal } from "../config/httpComman2";

export default class securityService {
  static async getConfigList() {
    return await http.post(`/oauth/config/list`, {
      ...signal,
    });
  }
  static async updateConfig(updatedData: []) {
    return await http.post("/oauth/config/update", updatedData, signal);
  }

  static async resendOtp(data: any) {
    return await http.post("/oauth/code/reset", { ...data }, signal);
  }

  static async lockAgent(data: any) {
    return await http.post("/oauth/lock", { ...data }, signal);
  }
  static async unlockAgent(data: any) {
    return await http.post("/oauth/unlock", { ...data }, signal);
  }
  static async getSessionsActive() {
    return await http.post("/oauth/sessions/active", {}, signal);
  }
  static async getAgentSessionsActive(data: any) {
    return await http.post("/oauth/sessions/active/admin", { ...data }, signal);
  }
  static async revokeSessionActiveAgent(data: any) {
    return await http.post("/oauth/sessions/revoke", { ...data }, signal);
  }
}
